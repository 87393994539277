import { useEffect, useRef } from 'react';

export const useDragtoScroll = () => {
  const refContainer = useRef<HTMLElement>(null);

  useEffect(() => {
    let isMounseDown = false;
    let mousedownElement: HTMLElement | null = null;
    const startPosition = { x: 0, y: 0, scrollLeft: 0 };

    const mousedown = (event: MouseEvent) => {
      startPosition.x = event.clientX;
      startPosition.y = event.clientY;
      startPosition.scrollLeft = refContainer?.current!.scrollLeft;

      mousedownElement = event.target as HTMLElement;
      isMounseDown = true;
      refContainer.current!.style.cursor = 'grabbing';
    };
    const mouseup = () => {
      isMounseDown = false;
      startPosition.x = 0;
      startPosition.y = 0;
      refContainer.current!.style.cursor = '';
    };

    const mousemove = (event: MouseEvent) => {
      const isCursorAtTable = refContainer.current?.contains(mousedownElement);
      if (isMounseDown && isCursorAtTable) {
        const deltaX = event.clientX - startPosition.x;
        refContainer.current!.scrollLeft = startPosition.scrollLeft - deltaX;
      }
    };

    document.addEventListener('mousedown', mousedown);
    document.addEventListener('mouseup', mouseup);
    document.addEventListener('mousemove', mousemove);

    return () => {
      document.removeEventListener('mousedown', mousedown);
      document.removeEventListener('mouseup', mouseup);
      document.removeEventListener('mousemove', mousemove);
    };
  }, []);

  return { refContainer };
};
